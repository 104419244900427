/* eslint-disable no-plusplus */
export const validateCPF = (cpf) => {
   cpf = cpf.replace(/\D/g, ''); // Remove non-digits
   if (cpf.length !== 11) {
      return false;
   }

   // Check if all digits are the same
   if (/^(\d)\1+$/.test(cpf)) {
      return false;
   }

   let sum = 0;

   for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i), 10) * (10 - i);
   }

   let remainder = (sum * 10) % 11;
   if (remainder === 10 || remainder === 11) {
      remainder = 0;
   }

   if (remainder !== parseInt(cpf.charAt(9), 10)) {
      return false;
   }

   sum = 0;
   for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i), 10) * (11 - i);
   }

   remainder = (sum * 10) % 11;
   if (remainder === 10 || remainder === 11) {
      remainder = 0;
   }

   if (remainder !== parseInt(cpf.charAt(10), 10)) {
      return false;
   }

   return true;
};

export const validatePhoneNumber = (inputValue) => {
   const numericValue = inputValue.replace(/\D/g, '');
   return numericValue.length === 11;
};
