import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, product, title, ...other }, ref) =>
(
   <>
      <Helmet>
         <title>{title}</title>
         {/* <meta property="og:type" content="website" />
         <meta property="og:title" content={product?.title} />
         <meta property="og:description" content={product?.description} />
         <meta property="og:image" content={product?.imagesUrl[0]} /> */}
      </Helmet>

      <Box ref={ref} {...other}>
         {children}
      </Box>
   </>
));

Page.propTypes = {
   children: PropTypes.node.isRequired,
   title: PropTypes.string.isRequired,
};

export default Page;
