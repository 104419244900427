import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
   Box,
   List,
   Link,
   Drawer,
   Collapse,
   ListItemText,
   ListItemIcon,
   ListItemButton,
   Stack,
   Container,
   Divider,
   Typography,
   Dialog,
   DialogContent,
   Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// config
import { NAVBAR } from '../../config';
// components
import Logo from '../../components/Logo';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import { IconButtonAnimate } from '../../components/animate';
import { NavSectionVertical } from '../../components/nav-section';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import DialogTitleComponent from '../../components/DialogTitleComponent';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import { validatePhoneNumber } from '../../utils/validate';
import axios from '../../utils/axios';
import { phoneNumberMask } from '../../utils/masks';
import useToggle from '../../hooks/useToggle';
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
   ...theme.typography.h6,
   // textTransform: 'capitalize',
   height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
   color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

MenuMobile.propTypes = {
   isOffset: PropTypes.bool,
   isHome: PropTypes.bool,
   navConfig: PropTypes.array,
};

export default function MenuMobile({ isOffset, isHome, navConfig }) {
   const { pathname } = useLocation();
   const navigate = useNavigate();
   const isMountedRef = useIsMountedRef();

   const { toggle: open, onOpen, onClose } = useToggle();

   const [error, setError] = useState('');

   const [isSubmitting, setIsSubmitting] = useState(false);

   const theme = useTheme();

   const [openDrawer, setOpen] = useState(false);

   const [drawerOpen, setDrawerOpen] = useState(false);

   const { user, loginClient, logout } = useAuth();

   const { enqueueSnackbar } = useSnackbar();

   useEffect(() => {
      if (drawerOpen) {
         handleDrawerClose();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pathname]);

   const handleLogout = async () => {
      try {
         //         console.log('logout');
         await logout();
         navigate(PATH_PAGE.root, { replace: true });

         if (isMountedRef.current) {
            setDrawerOpen(false);
         }
      } catch (error) {
         console.error(error);
         enqueueSnackbar('Unable to logout!', { variant: 'error' });
      }
   };

   const handleOpen = () => {
      setOpen(!openDrawer);
   };

   const handleDrawerOpen = () => {
      setDrawerOpen(true);
   };

   const handleDrawerClose = () => {
      setDrawerOpen(false);
   };

   const LoginSchema = Yup.object().shape({
      user: Yup.object().shape({
         phone: Yup.string()
            .required('Telefone é obrigatório.')
            .test('validation-phone', 'Telefone inválido', (value) => validatePhoneNumber(value)),
      }),
   });

   const defaultValues = {
      user: {
         phone: '',
      },
   };

   const methods = useForm({
      resolver: yupResolver(LoginSchema),
      defaultValues,
   });

   const { reset, watch, setValue, getValues, handleSubmit } = methods;

   const values = watch();

   const onSubmit = async () => {
      //      console.log('aqui login', values);

      const user = {
         phone: values.user.phone,
      };

      if (!user.phone) {
         setError('Informe um telefone para prosseguir');
         return;
      }

      if (!validatePhoneNumber(user.phone)) {
         setError('Informe um telefone válido');
         return;
      }

      setIsSubmitting(true);

      // realiza cadastro do usuario
      try {
         const response = await axios.post('/api/auth/login/client', { phone: values.user.phone });
         //         console.log('Busquei o login', response);
         if (response.data.signIn) {
            setError('Telefone não encontrado');
         } else {
            await loginClient(values.user.phone);
            //            console.log('Login efetuado com sucesso', user);
            reset();
            setError('');
            onClose();
         }
         setIsSubmitting(false);
      } catch (error) {
         if (error.signIn) setIsSubmitting(false);
         console.error('ERRORZITO', error);
      }
   };

   return (
      <>
         <IconButtonAnimate
            onClick={handleDrawerOpen}
            sx={{
               ml: 1,
               ...(isHome && { color: 'common.white' }),
               // ...(isOffset && { color: 'text.primary' }),
            }}
         >
            <Iconify icon={'eva:menu-2-fill'} />
         </IconButtonAnimate>

         <Stack>
            <Dialog fullWidth open={open} onClose={onClose}>
               <DialogTitleComponent
                  icon={user ? 'uil:cart' : 'material-symbols:key-rounded'}
                  title={user ? 'Meus números' : 'Login'}
                  onClose={onClose}
               />

               <DialogContent>
                  <Stack spacing={2}>
                     {!user && (
                        // <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2}>
                           {/* <RHFTextField
                                    name="user.phone"
                                    label="Telefone"
                                    required
                                    onChange={(event) => {
                                       setValue('user.phone', phoneNumberMask(event.target.value));
                                    }}
                                 /> */}

                           <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                              <RHFTextField
                                 name="user.phone"
                                 label="Telefone"
                                 required
                                 sx={{ mb: 2 }}
                                 onChange={(event) => {
                                    setValue('user.phone', phoneNumberMask(event.target.value));
                                 }}
                              />

                              {!error && <Alert severity="warning">Informe seu telefone para continuar</Alert>}
                              {error && <Alert severity="error">{error}</Alert>}

                              <LoadingButton
                                 fullWidth
                                 size="large"
                                 variant="contained"
                                 loading={isSubmitting}
                                 type="submit"
                                 onClick={onSubmit}
                                 sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}
                              >
                                 Continuar
                              </LoadingButton>
                           </FormProvider>
                        </Stack>
                        // </FormProvider>
                     )}

                     {user && <Typography>Já estou logado com o usuário {user.name}!</Typography>}
                  </Stack>
               </DialogContent>
            </Dialog>
         </Stack>

         <Drawer
            anchor="top"
            open={drawerOpen}
            onClose={handleDrawerClose}
            ModalProps={{ keepMounted: true }}
            PaperProps={{
               sx: { pb: 5, width: '100%', height: '100%', bgcolor: '#000' },
            }}
         >
            <Container sx={{ maxWidth: '700px !important' }}>
               <Scrollbar>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                     <Logo sx={{ mx: 2.5, my: 3 }} />
                     <Iconify
                        icon={'material-symbols:close'}
                        width={36}
                        height={36}
                        onClick={handleDrawerClose}
                        cursor={'pointer'}
                        sx={{ mx: 2.5, my: 3 }}
                     />
                  </Stack>
                  <Divider />
                  <List disablePadding>
                     {navConfig.map((link) => (
                        <Stack key={link.title}>
                           <MenuMobileItem item={link} isOpen={openDrawer} onOpen={handleOpen} />
                           <Divider />
                        </Stack>
                     ))}
                  </List>

                  {!user && (
                     <LoadingButton
                        fullWidth
                        disabled={openDrawer}
                        size="large"
                        variant="contained"
                        onClick={
                           onOpen

                           // () => {

                           // if (user) {
                           //    setOpen(true);
                           // } else {
                           //    handleLogout();
                           // }
                        }
                        loading={false}
                        // sx={{ whiteSpace: 'nowrap' }}
                        sx={{ display: 'flex', flexDirection: 'row' }}
                     >
                        <Iconify icon={'material-symbols:login'} width={18} height={18} />
                        <Typography ml={1} variant="button">
                           Entrar
                        </Typography>
                     </LoadingButton>
                  )}

                  {user && (
                     <LoadingButton
                        fullWidth
                        disabled={openDrawer}
                        size="large"
                        variant="contained"
                        onClick={
                           handleLogout
                           // () => {

                           // if (user) {
                           //    setOpen(true);
                           // } else {
                           //    handleLogout();
                           // }
                        }
                        loading={false}
                        // sx={{ whiteSpace: 'nowrap' }}
                        sx={{ display: 'flex', flexDirection: 'row' }}
                     >
                        <Iconify icon={'material-symbols:login'} width={18} height={18} />
                        <Typography ml={1} variant="button">
                           Sair
                        </Typography>
                     </LoadingButton>
                  )}
               </Scrollbar>
            </Container>
         </Drawer>
      </>
   );
}

// ----------------------------------------------------------------------

MenuMobileItem.propTypes = {
   isOpen: PropTypes.bool,
   item: PropTypes.shape({
      children: PropTypes.array,
      icon: PropTypes.any,
      path: PropTypes.string,
      title: PropTypes.string,
   }),
   onOpen: PropTypes.func,
};

function MenuMobileItem({ item, isOpen, onOpen }) {
   const { title, path, icon, children } = item;

   if (children) {
      return (
         <>
            <ListItemStyle onClick={onOpen}>
               <ListItemIcon>{icon}</ListItemIcon>
               <ListItemText disableTypography primary={title} />
               <Iconify icon={isOpen ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} sx={{ ml: 1 }} />
            </ListItemStyle>

            <Collapse in={isOpen} unmountOnExit>
               <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
                  <NavSectionVertical
                     navConfig={children}
                     sx={{
                        '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                           height: 200,
                           backgroundSize: 'cover',
                           backgroundPosition: 'center',
                           bgcolor: 'background.neutral',
                           backgroundRepeat: 'no-repeat',
                           backgroundImage: 'url(/assets/illustrations/illustration_dashboard.png)',
                           '& > *:not(.MuiTouchRipple-root)': { display: 'none' },
                        },
                     }}
                  />
               </Box>
            </Collapse>
         </>
      );
   }

   if (title === 'Documentation') {
      return (
         <Link href={path} target="_blank" rel="noopener" underline="none">
            <ListItemStyle>
               <ListItemIcon>{icon}</ListItemIcon>
               <ListItemText disableTypography primary={title} />
            </ListItemStyle>
         </Link>
      );
   }

   return (
      <ListItemStyle
         to={path}
         component={RouterLink}
         end={path === '/'}
         sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            '&.active': {
               color: 'primary.main',
               fontWeight: 'fontWeightMedium',
               bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
         }}
      >
         <ListItemIcon>{icon}</ListItemIcon>
         <ListItemText disableTypography primary={title} sx={{ fontSize: '14px' }} />
      </ListItemStyle>
   );
}
